<template>
  <content-wrapper title="Conent Detail" back>
    <template slot="actions">
      <el-button size="small" type="success" @click="submitForm('contentForm')">
        <i class="el-icon-check mr-1"></i>
        {{ labels.LBL_UPDATE }}
      </el-button>
    </template>

    <el-form
      ref="contentForm"
      v-loading="loading"
      :rules="rules"
      :model="editableContent"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col :span="10">
          <el-form-item prop="title" :label="labels.LBL_TITLE">
            <el-input v-model="editableContent.title" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="labels.LBL_SUBTITLE">
            <el-input v-model="editableContent.subtitle"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="content" :label="labels.LBL_CONTENT">
            <rich-editor v-model="editableContent.content" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </content-wrapper>
</template>

<script>
  import { labels, rules } from "@/common";
  import { mapState } from "vuex";

  import ContentWrapper from "@/components/layouts/AdminContentWrapper";
  import RichEditor from "@/components/RichEditor";

  export default {
    components: {
      ContentWrapper,
      RichEditor,
    },

    props: {
      contentId: {
        required: true,
        type: [String, Number],
      },
    },

    data() {
      return {
        labels,
        rules,

        editableContent: {
          title: "",
          subtitle: "",
        },
      };
    },

    watch: {
      contentId: {
        immediate: true,
        handler(nv) {
          this.loadEditableContentDetail(nv);
        },
      },

      editableDetail: {
        handler(nv) {
          this.setEditableContentDetail(nv);
        },
      },
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        editableDetail: (state) => state.editableContent.editableContentDetail,
      }),
    },

    methods: {
      loadEditableContentDetail(id) {
        this.$store.dispatch("editableContent/getEditableContentDetail", id);
      },

      setEditableContentDetail(detail) {
        this.editableContent = { ...detail };
      },

      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$store.dispatch(
              "editableContent/updateEditableContent",
              this.editableContent
            );
          }
        });
      },
    },
  };
</script>

<style></style>
